    *, *:before, *:after {
    box-sizing: inherit;
  }
  * {margin:0;padding:0;border:0 none; }
  
/* NAV BAR */
.lineDecoration {
    border-top: 2px solid #000000;
    max-width: 120px;
    margin: 2px auto 0 auto;
}

.dropdown {
    position: relative;
    display: inline-block;
}
  
.dropdown-content {
    display: flex;
    justify-content:center;
    align-content: center;
}

.dropdown-content-align {
    display: none;
    position: absolute;
    background-color: #000000;
    z-index: 1;
}
  
.dropdown-content a {
    color: #FFFFFF;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content-align {
    display: block;
}

.dropdown:hover .lineDecoration {
    border-top: 2px solid #5D5D60;
}

@media (max-width:700px) {
    .dropdown {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .dropdown-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .dropdown-content-align {
        display: none;
        background-color: #000000;
        position: relative;
    }

    .dropdown:hover .dropdown-content-align {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
}

/* CHECKBOX */
.cbx {
    margin: auto;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: flex-end;
}
  .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    margin: 10px;
  }
  .cbx span:first-child {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
  }
  .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  
  .inp-cbx:checked + .cbx span:first-child {
    background: #000000;
    border-color: #000000;
    animation: wave 0.4s ease;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  /*CHECKBOX*/

  .checkBox {
    position: relative;
    width: 25px;
    height: 25px;
    color: #363839;
    border: 2px solid #3b9a83;
    border-radius: 50%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    margin-right: 5px;
  }
  .checkBox::before {
    position: absolute;
    content: "";
    display: block;
    left: 6px;
    top: 2px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  .checkBox:checked{
    /* input[type="checkbox"]:checked { */
      color: #fff;
      border-color: #3b9a83;
      background: #3b9a83;
  }
  .checkBox:checked::before{
    /* input[type="checkbox"]:checked::before { */
      opacity: 1;
  }
  .labelCheckBox {
    position: relative;
    cursor: pointer;
    padding: 0 0.25em 0;
    display: flex;
    user-select: none;
  }
  .labelCheckBox::before {
    position: absolute;
    content: attr(data-content);
    color: #9c9e9f;
    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    text-decoration: line-through;
    text-decoration-thickness: 3px;
    text-decoration-color: #363839;
    transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .textCheckBox {
    font-size: 14px;
    padding-top: 3px;
  }

 /*RADIO BUTTON*/
 .radio {
  position: relative;
  cursor: pointer;
  line-height: 25px;
  font-size: 14px;
  margin: 10px;
}
.radio .label {
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid #c8ccd4;
  border-radius: 100%;
}
.radio .label:after {
  content: '';
  position: absolute;
  top: 2.4px;
  left: 2.4px;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #000000;
  transform: scale(0);
  transition: all 0.2s ease;
  opacity: 0.08;
  pointer-events: none;
}

input[type="radio"]:checked + .label {
  border-color: #000000;
}
input[type="radio"]:checked + .label:after {
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
}

.hidden {
  display: none;
}

/*LOADING ICON*/
.loader {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-top: 5px solid #EFAB23;
  border-right: 5px solid #efefef;
  border-bottom: 5px solid #efefef;
  border-left: 5px solid #efefef;
  border-radius: 100px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}


/* SIDE BAR */


/* * {  */
  /* -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
  -o-box-sizing: border-box; 
  box-sizing: border-box;  */
  /* adds animation for all transitions */ 
  /* -webkit-transition: .25s ease-in-out; 
  -moz-transition: .25s ease-in-out; 
  -o-transition: .25s ease-in-out; 
  transition: .25s ease-in-out; 
  margin: 0; 
  padding: 0; 
  -webkit-text-size-adjust: none;  */
  /* }  */
  
  /* Makes sure that everything is 100% height */ 
  
  html,body { 
    height: 100%; 
  /* overflow: hidden;  */
  } 
  
  /* gets the actual input out of the way; 
  we're going to style the label instead */ 
  
  #drawer-toggle { 
    position: absolute; 
    opacity: 0; 
  }
  
  #drawer-toggle-label { 
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    left: 0px; 
    height:50px; 
    width: 50px; 
    display: block; 
    position: fixed; 
    background: rgba(255,255,255,.0); 
    z-index: 1; 
  } 
  
  /* adds our "hamburger" menu icon */ 
  
  #drawer-toggle-label:before { 
    content: ''; 
    display: block; 
    position: absolute; 
    height: 2px; 
    width: 24px; 
    background: #FFF; 
    left: 13px; 
    top: 18px; 
    box-shadow: 0 6px 0 #FFF, 0 12px 0 #FFF; 
  } 
  
  header { 
    width: 100%; 
    position: fixed; 
    left: 0px; 
    background: #3b9a83; 
    padding: 10px 10px 10px 50px; 
    font-size: 30px; 
    line-height: 30px; 
    z-index: 0; 
    color: #FFF;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    gap:15px;
  }
  
  /* drawer menu pane - note the 0px width */ 
  
  #drawer { 
    position: absolute; 
    /* top: 0;  */
    left:-300px; 
    height: 100%; 
    width: 300px; 
    background: #3a9a83; 
    overflow-x: hidden; 
    padding: 20px; 
    -webkit-overflow-scrolling: touch; 
  } 
  
  /* actual page content pane */ 
  
  #page-content { 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px; 
    width: 100%; 
    height: calc(100% - 50px); 
    overflow-x:hidden; 
    -webkit-overflow-scrolling: touch; 
    padding: 20px; 
  }
  
  /* checked styles (menu open state) */ 
  
  #drawer-toggle:checked ~ #drawer-toggle-label { 
    height: 100%; 
    width: calc(100% - 300px); 
  } 
  
  #drawer-toggle:checked ~ #drawer-toggle-label, 
  #drawer-toggle:checked ~ header { 
    left: 300px; 
  } 
  
  #drawer-toggle:checked ~ #drawer { 
    left: 0px; 
  } 
  
  #drawer-toggle:checked ~ #page-content { 
    margin-left: 300px; 
  }
  
  /* Menu item styles */ 
  
  #drawer ul { 
    list-style-type:none; 
  } 
  
.navLinks { 
  display:block; 
  padding:10px; 
  color:#efefef; 
  text-decoration:none; 
  } 
  
  
  /* Responsive MQ */ 
  
  @media all and (max-width:350px) { 
  
    #drawer-toggle:checked ~ #drawer-toggle-label { 
    height: 100%; 
    width: 50px; 
    } 
    
    #drawer-toggle:checked ~ #drawer-toggle-label, 
    #drawer-toggle:checked ~ header { 
    left: calc(100% - 50px); 
    } 
    
    #drawer-toggle:checked ~ #drawer { 
    width: calc(100% - 50px); 
    padding: 20px; 
    } 
    
    #drawer-toggle:checked ~ #page-content { 
    margin-left: calc(100% - 50px); 
    } 
  
  }
